@font-face {
  font-family: "Futura";
  src: url('./Fonts/FU______.TTF');
}
@font-face {
  font-family: "FuturaBold";
  src: url('./Fonts/FUB_____.TTF');
}
@font-face {
  font-family: "FuturaBook";
  src: url('./Fonts/FUBO____.TTF');
}
@font-face {
  font-family: "FuturaHeavy";
  src: url('./Fonts/FUH_____.TTF');
}
@font-face {
  font-family: "FuturaHO";
  src: url('./Fonts/FUHO____.TTF');
}
@font-face {
  font-family: "FuturaLight";
  src: url('./Fonts/FUL_____.TTF');
}
@font-face {
  font-family: "FuturaO";
  src: url('./Fonts/FUO_____.TTF');
}

:root {
  --main-old-bg-color: #009eb4;
  --main-bg-color: #fbce07;
  --main-layout-bg-color: #f7f7f7;
  --yellow-bg-color: #fbce07;
  --yellow-lite-color: #fde26a;
  --red-bg-color: #dd1d21;
  --white-txt-color: #ffffff;
  --white-bg-color: #ffffff;
  --success-color: #bed50f;
  --light-grey-color: #f7f7f7;
  --dark-grey-color: #d9d9d9;
  --font-black-color: #404040;
  --toastify-icon-color-success: #bed50f !important;
}
.chatbot-wrapper {
  position: fixed;
  bottom: 7px;
  right: 7px;
  z-index: 9999;
  width: 27em;
  border: 1px solid #d8d8d8;
  transition: all 0.4s ease-in-out;
}
.chatbot-wrapper.maxScreen{
  width: 85vw;
}
.chatbot-wrapper.maxScreen .react-chatbot-kit-chat-inner-container {
  height: 87vh;
}
.chatbot-wrapper.maxScreen .react-chatbot-kit-chat-message-container {
  height: 90%
}
.chatbot-wrapper .react-chatbot-kit-chat-container {
  width: 100%;
}
.chatbot-wrapper .react-chatbot-kit-chat-message-container.chatbot-container-minimize {
  display: none;
}
.chatbot-wrapper .react-chatbot-kit-chat-input-container.chatbot-container-minimize {
  display: none;
}
.chatbot-wrapper  .react-chatbot-kit-chat-inner-container.chatbot-container-minimize {
  height: auto;
}
.chatbot-wrapper.maxScreen.chatbot-container-minimize {
  width: 27em;
}
.hatbot-wrapper .react-chatbot-kit-chat-bot-message-container {
  margin-bottom: 30px;
}
.chatbot-wrapper .react-chatbot-kit-chat-bot-message-container .react-chatbot-kit-chat-bot-message {
   background-color: #f5f5f5;
   color: black;
   margin-left: inherit;
   width: fit-content;
}
.chatbot-wrapper .react-chatbot-kit-chat-bot-message-container .react-chatbot-kit-chat-bot-message p{
  margin-bottom: 0;
}
.chatbot-wrapper .react-chatbot-kit-chat-bot-message-container .react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #f5f5f5;
}
.chatbot-wrapper .react-chatbot-kit-user-chat-message-container {
  gap: 8px;
}
.chatbot-wrapper .react-chatbot-kit-user-chat-message {
  color: #003C8A;
}
.chatbot-wrapper .chatbot-loader-container {
  align-items: center;
}
.chatbot-button {
  background-color: transparent;
  border: none;
  color: black;

}
.chatbot-wrapper .react-chatbot-kit-chat-btn-send {
  background-color: white;
  border: 1px solid #d8d8d8;
}
.chatbot-wrapper .react-chatbot-kit-chat-btn-send-icon {
  fill : black; 
}
.chatbot-wrapper .chatbot-loader-container {
  justify-content: flex-start;
}
.dancing-icon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease-in-out;
}
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-2px); }
  50% { transform: translateX(2px); }
  75% { transform: translateX(-2px); }
}
/* IconStyles.css */
.clear-icon-container {
  position: absolute;
  bottom: 3.2rem;
  left: 1.2rem;
  border-radius: 50%;
}

.dancing-icon {
  width: 40px;
  height: 30px;
  transition: transform 0.3s ease-in-out;
  z-index: 10000;
  position: relative;
}
.clear-icon-data-load.clear-icon-container {
  opacity: 0.5;
}

.clear-icon-container:hover .dancing-icon {
  animation: shake 0.5s infinite alternate;
}

.hover-text {
  font-size: xx-small;
  visibility: hidden;
  width: 55px;
  background-color: #003C8A;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  top: 15%; 
  left: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 1000;
}

.clear-icon-container:hover .hover-text {
  visibility: visible;
  opacity: 1;
}
.clear-icon-container:hover .hover-text:hover {
  z-index: 10000;
}

.app-bg-color{
  background-color: var(--white-bg-color) !important;
}
.app-layout-bg-color{
  background-color: var(--main-layout-bg-color);
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

body{
  font-family: "Futura", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
}
.filter-list .disabled{
  pointer-events: none !important;
}
.filter-list li div, .filter-list li label{
  cursor: pointer;
}
.filter-list li.disabled label{
  color: #6c757d !important;
}
.search-input-field{
  min-height: 50px;
  border-radius: 0px !important;
}
.search-input-field::-webkit-input-placeholder {
  font-size: 16px;
  color: var(--dark-grey-color) !important;
}
#search-icon-btn{
  background:url('./assets/search-icon.svg') no-repeat center;
  background-color: var(--yellow-bg-color);
  position: absolute;
  cursor: pointer;
  right: 3px;
  top: 3px;
  padding: 22px 25px;
  border: none;
}
.advanced-search-active #search-icon-btn{
  background:url('./assets/search-icon-white.svg') no-repeat center;
  background-color: var(--red-bg-color);
}
#search-clear-btn{
  background:url('./assets/clear-icon.svg') no-repeat center;
  background-color: transparent;
  width: 25px;
  height: 20px;
  border: none;
  position: absolute;
  top: 15px;
  right: 0px;
  transition: all .5s ease-in 0s;
}
.advanced-search-active .search-input-field:focus~#search-clear-btn{
  transition: all .5s ease-in 0s;
  right: 55px;
}
.main-page-search-field .search-input-field{
  min-height: 46px !important;
  border-radius: 4px !important;
  border: 1px solid transparent;
}
.main-page-search-field .search-input-field::-webkit-input-placeholder {
  font-size: 16px;
}
.main-page-search-field #search-icon-btn{
  border-radius: 4px;
  padding: 20px 22px;
}

.header-bar{
  background-color: var(--main-bg-color);
}
/* -------------------------------------Sidebar-------------------------------------------------------- */
.sidebar{
  height: 100%;
  width: 280px;
  min-height: 100vh;
}
.landing-page-left-split{
  background-color: var(--white-bg-color);
}
.brand-name{
  position: relative;
  font-family: "FuturaBold", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: var(--red-bg-color);
  font-size: 32px;
}
.brand-name-inner h2.brand-name{
  font-weight: 800;
}

.brand-name-wrapper::before{
  position: absolute;
  content: '';
  left: -9%;
  top: 3%;
  width: 1px;
  height: 40px;
  background-color: var(--dark-grey-color);
}
.heaeder-menu div.download-report{
  margin-top: -4px;
}
.heaeder-menu div.download-report, .heaeder-menu div.user-detail{
font-size: 14px;
}
.user-detail .dropdown-menu{
right: 0 !important;
left: initial !important;
}
.landing-page-left-split .search-input-field{
  background: var(--white-txt-color);
  font-size: 1.5em;
}


.landing-page-right-split{
  background-image: url('./assets/illustration_landing_page.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.logo{
  width: 45px;
}
/* ----------------- Sidebar filter----------- */
.sidebar-container{
  margin: 9px 0px  7px 7px;
}
.filter, .clearall{
  color: #212529;
}
.clearall:hover{
opacity: 0.7;
}
.filter-list{
  transition: all 1s ease 0s;
  font-size: 0.875rem;
}
.filter-list.disabled{
  pointer-events: none !important;
  opacity: 0.7;
}
.filter-list .list-group-item div[aria-expanded="false"] .filter-chevron{
  transition: all .3s ease 0s;
  width: 10px;
}
.filter-list .list-group-item div[aria-expanded="true"] .filter-chevron{
transform: rotate(-180deg);
transition: all .3s ease 0s;
width: 10px;
}
.filter-list .label-wrap{
  display: inline-flex;
}
.filter-list .label{
  text-overflow: ellipsis;
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
}
.filter-list li{
  /* background-color: #66C4D1 !important; */
  border: none;
}
.filter-list ul li{
  /* background-color: #B7E1E9 !important; */
  padding-left: 20px !important;
}

.filter-list ul  ul li{
  padding-left: 35px !important;
}

.filter-list .list-group-item label{
  color: #212529 !important;
}

input:indeterminate {
  box-shadow: 0 0 1px 1px transparent;
}
.card-box-shadow.card {
  border:none;
}
.shadow{
box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)!important;
}
/* ------------------Search Item--------------------- */
.search-results-inner{
  max-height: 1000px;
  overflow-y: auto;
}
@media screen and (min-height:1200px){
  .search-results-inner{
    max-height: 1500px;
  }
}
.search-item .content-l span, .search-item .content-r button, .search-item .content-left span, .search-item .associations-btns button,
.search-item .content-right button.action-bowtie{
  font-size: 10px !important;
}
.search-item .content-r button.action-bowtie, .action-learn-btns button, .search-item .associations-btns .dropdown-toggle,
.search-item .content-right button.action-bowtie{
  background-color: var(--red-bg-color);
  color: #ffffff;
  border-radius: 0;
}
.search-item-header-text-content{
  cursor: pointer;
}
.search-item-header-text-content .header-text, .search-item-header-text-content .sub-header-text{
  text-overflow: ellipsis;
  max-width: 650px;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width:1440px){
  .search-item-header-text-content .header-text, .search-item-header-text-content .sub-header-text{
    max-width: 550px;
  }
}
@media screen and (max-width:1390px){
  .search-item-header-text-content .header-text, .search-item-header-text-content .sub-header-text{
    max-width: 450px;
  }
}
@media screen and (max-width:1200px){
  .search-item-header-text-content .header-text, .search-item-header-text-content .sub-header-text{
    max-width: 310px;
  }
}
.search-item-header-text-content .sub-header-text{
  font-family: "FuturaLight", Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.search-item-report-controls label{
  font-size: 10px;
}
.search-item-report-controls .form-check-input{
  margin-top: .45rem;
}
.search-item-inner{
  background-color: var(--light-grey-color);
}
.search-item-wrapper .detail-content{
  font-family: "FuturaLight", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 1rem;
  background-color: var(--light-grey-color);
}
.verticalbar{
  position: relative;
  display: inline-block;
  top: 2px;
  width: .5px;
  height: 10px;
  background-color: #212529;
}
/* --------------------Action/Associate learn------------ */
.associated-learn-items .card, .action-items .card, .assoc-moc-items .card, 
.associated-quality-items .card, .associated-quality-risk-items .card{
background-color: var(--light-grey-color);
border-radius: 0px !important;
}
.action-learn-results{
  max-height: 500px;
  overflow-y: auto;
}
@media screen and (min-height:650px){
  .action-learn-results{
    max-height: 1060px;
  }
}
.action-learn-results .action-items .titles p, .action-learn-results .associated-learn-items .titles p,
.assoc-moc-items p.subdescription, .associated-quality-items p.subdescription, .associated-quality-risk-items p.subdescription
{
  text-overflow: ellipsis;
  max-width: 650px;
  white-space: nowrap;
  overflow: hidden;
}
/* ------------------learn item-------------- */
.learnings-inner{
  max-height: 100vh;
  overflow-y: auto;
}
.learn-item-header-text-content .header-text, .learn-item-header-text-content .sub-header-text{
  text-overflow: ellipsis;
  max-width: 650px;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width:1440px){
  .learn-item-header-text-content .header-text, .learn-item-header-text-content .sub-header-text{
    max-width: 550px;
  }
}
@media screen and (max-width:1390px){
  .learn-item-header-text-content .header-text, .learn-item-header-text-content .sub-header-text{
    max-width: 450px;
  }
}
@media screen and (max-width:1200px){
  .learn-item-header-text-content .header-text, .learn-item-header-text-content .sub-header-text{
    max-width: 310px;
  }
}
.learn-item-header-text-content .sub-header-text{
  font-family: "FuturaLight", Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.learn-item-header-text-content{
  cursor: pointer;
}
.learn-item{
  transition: all 1s ease 0s;
}

.learn-item-report-controls label, .emoc-item-report-controls label{
  font-size: 10px;
}
.emoc-item-report-controls label{
  position: relative;
  top: -2px;
}
.learn-item-report-controls .form-check-input{
  margin-top: .45rem;
}
.learn-item-wrapper{
  background-color: var(--light-grey-color);
}
.learn-item-wrapper .detail-content{
  font-family: "FuturaLight", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 1rem;
}


/* -------------------Key Topics------------ */
.key-btn{
  color: #212529 !important;
}
.key-btn-divider{
  height: 2px;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
  width: 100%;
}

/* -------------------Graph Analysis & Tab-------------- */
#heatmaplegend{
  position: absolute;
  right: 0;
  top: 10px;
}

.d3-tooltip{
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px;
  font-size: 12px;
  transition: all 0.1s ease-in 0s;
  text-align: center;
}
.d3-tooltip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  content: "\25BC";
  position: absolute;
  text-align: center;
}
.d3-tooltip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}
.heatmap-wrapper{
  position: relative;
  min-height: 400px;
}
.heatmap-svg-wrapper{
  overflow-x: auto;
  overflow-y: hidden;
  width: 650px;
  position: relative;
}

@media (min-width:1280){
.heatmap-svg-wrapper{width: 90%;}
}
#heatmap rect.rect.clicked, .heatmap rect.rect.clicked{
  fill: var(--yellow-bg-color) !important;
  transition: all 0.3s ease-in 0s;
}
#heatmap .x.axis text, #heatmap .y.axis text{
  font-family: "Futura", Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.heatmap-name{
  font-size: 14px;
}
.heatmap-name::first-letter{
  text-transform: capitalize;
}
.plot-menu{
  /* position: absolute; */
  top: 10px;
  right: 9px;
}
.plot-menu .icon-wrap{
  width: 25px;
  height: 25px;
  background-color: var(--yellow-bg-color);
  text-align: center;
  margin: 0px 7px;
  cursor: pointer;
}
.plot-menu .icon-wrap img{
  vertical-align: sub;
}
.graph-analysis{
  position: relative;
}
.tab-wrapper{
  background-color: #ffffff;
}
.tab-wrapper .nav-tabs{
  border-bottom: none;
}
.tab-wrapper .nav-tabs li a{
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: transparent !important;
  color: #212529;
}
#mainLayoutTab {
  background-color: var(--light-grey-color)
}
.tab-wrapper #mainLayoutTab.nav-tabs li a.active{
  background-color: #ffffff !important;
  border-bottom-color: transparent !important;
  color: var(--red-bg-color);
}
.tab-wrapper .nav-tabs li a.active{
  border-bottom-width: 2px;
  border-bottom-color: var(--red-bg-color);
}
.tab-wrapper .nav-tabs li a:hover{
  border-color: transparent;
}
.tab-wrapper .nav-tabs li a.active:hover{
  border-bottom-width: 2px;
  border-bottom-color: var(--red-bg-color);
}


/* ----------------------Advanced Search------------------------- */
.right-sidebar{
  padding-top: 16px;
}
.advanced-search-wrapper.active{
  display: block !important;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.advanced-search-wrapper{
  display: none !important;
  transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
}
.adv-search-close{
  position: absolute;
  right: 10px;
  top: 10px;
}
.adv-search-close:hover{
  font-weight: bolder;
}
.material-form-control{
  box-shadow: none !important;
}
.material-form-control.form-control:focus{
  /* background-image: linear-gradient(0deg,#009DB3 2px,rgba(63,81,181,0) 0),linear-gradient(0deg,rgba(0,0,0,.26) 1px,transparent 0); */
  background-size: 100% 100%,100% 100%;
  transition-duration: .3s;
}
.material-form-control.form-control{
  background: no-repeat bottom,50% calc(100% - 1px);
  background-size: 0 100%,100% 100%;
  border: 0;
  transition: background 0s ease-out;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid var(--dark-grey-color);
  border-radius: 0;
}
.dropdown-wrapper input.form-datalist:focus{
  border: 1px solid var(--yellow-lite-color) !important;
}

.chip{
  border: 1px solid var(--dark-grey-color);
  width: 100px;
}
.chip .label{
  text-overflow: ellipsis;
  max-width: 80px;
  white-space: nowrap;
  overflow: hidden;
}
.chip-colse{cursor: pointer;}
.form-datalist{
  width: 100%;
  border: 1px solid var(--dark-grey-color);
}
.form-datalist::-webkit-input-placeholder{
  font-size: .8rem;
  padding: 0px !important;
}
.form-datalist:focus{
  outline-color: var(--dark-grey-color);
  border-radius: 0px !important;
}
@media (min-width:1024px){
  .form-datalist, .chip{
    /* width: 75px; */
  }
}
@media (min-width:1281px) and (max-width:1440px){
  .form-datalist, .chip{
    /* width: 90px; */
  }
}
.advance-search-clear-btn:hover{
  opacity: 0.5;
}
/* ---------------spinner------------- */
.racespinner-wrapper{
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
}
.racespinner-wrapper-inner{
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
  /* position: fixed; */
  width: 100%;
  left: 0;
  top: 0;
}
.racespinner{
  width: 140px;
  height: 130px;
}

/* -----------------download-------------- */
.download-report-wrapper{
  position: absolute;
  z-index: 9999;
  top: 30px;
  right: 0px;
  background-color: var(--white-bg-color);
  box-shadow: 0px 1px 6px #ccc;
}
.download-report-wrapper ul.list-group{
  max-height: 212px;
  overflow-y: auto;
  background-color: #ffffff;
  min-width: 120px;
}
.download-btn{
  color: #ffffff;
  background-color: var(--yellow-bg-color);
}
.download-report-toggle:hover{
  color: #212529 !important;
}
.download-report-toggle{
  color: #212529;
}
.download-report-wrapper ul li{
  padding: 7px 5px 0px 5px;
}
.download-report-wrapper ul li label{
  text-overflow: ellipsis;
  max-width: 145px;
  overflow: hidden;
  white-space: nowrap
}
/* ------------------- */
.login-form input{
height: 55px;
outline: none !important;
}
.login-form button{
  min-width: 200px;
  background-color: #ffffff;
  color: var(--yellow-bg-color);
  font-size: 25px;
}
.login-form input::-webkit-input-placeholder {
  font-size: 25px;
  color: var(--dark-grey-color) !important;
  opacity: 0.4;
}

/* ==============Modal========== */
.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: gray; */
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear .25s,opacity .25s 0s,transform .25s;
  z-index: 1;
}
.modal-wrapper.visible {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: visibility 0s linear 0s,opacity .25s 0s,transform .25s;
}
.modal-wrapper .custom-modal {
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  min-width: 500px;
}
.modal-wrapper .title {
  font-size: 18px;
}
.modal-wrapper .button-container {
  text-align: right;
}
.modal-wrapper button.ok,.modal-wrapper button.cancel {
  min-width: 80px;
  background-color:var(--red-bg-color);
  border-color: var(--red-bg-color);
  border-style: solid;
  padding: 3px;
  color:white;
  cursor: pointer;
}
.modal-wrapper button.cancel {
  background-color: #ffffff;
  border-color: var(--dark-grey-color);
  color: var(--font-black-color);
}


/*
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.navbar.navbar-light{
  background-color: var(--main-bg-color);
}
#profiledropdown::after{
  color: #ffffff;
}
.react-grid-Toolbar .tools{
  padding: 5px 0px;
}
.react-grid-Toolbar .tools .btn{
  margin-left: 0px;
  font-size: .8rem;
  background: var(--red-bg-color);
  border: none;
  border-radius: 0;
  margin-right: 2px;
  padding: .15rem .75rem;
  color: #ffffff;
}
.react-grid-Toolbar .tools .btn.rdg-filter-btn.filter-active{
  pointer-events: none;
  opacity: .65;
}
.react-grid-Toolbar .tools .btn.rdg-clear-filter-btn{
  pointer-events: none;
  opacity: .65;
}
.react-grid-Toolbar .tools .btn.rdg-clear-filter-btn.filter-active{
  pointer-events: all;
  opacity: 1;
}
.react-grid-Grid .form-control{
  height: 30px;
  padding-left: .3rem;
}
.react-grid-Grid .form-control::placeholder{
  font-size: 12px;
  line-height: 1;
}
.react-grid-Grid .form-control:focus{
  border-color: var(--dark-grey-color);
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
.drag-handle { display: none; }
.dropdown .dropdown-toggle::after{
  color: #212529;
}
.modal.user-form-modal.show{
  display: block !important;
}
.modal.user-form-modal{
  display: none !important;
}
.modal.user-form-modal button.close, .userpopup-close.close, .mostnotpopup-close.close, .userinfomodal .close, #popupModalCustom .close{
  border: 1px solid #404040;
  border-radius: 50%;
  padding: 2px !important;
  margin: 0px !important;
  width: 16px;
  height: 16px;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
}
.modal.user-form-modal button.close span{
  position: relative;
  top: -0.5px;
}
.add-user{
  padding: 6px;
  background-color: var(--main-bg-color);
  border-radius: 4px;
}
.modal.user-form-modal .modal-header{
  border-bottom: none !important;
}
.modal.user-form-modal .modal-content{
  border-radius: 0px !important;
}
.modal.user-form-modal .modal-footer button{
  width: 150px;
}
.modal.user-form-modal .modal-footer button.btn-close{
 border: 2px solid var(--dark-grey-color);
}
.modal.user-form-modal .modal-footer{
  border-top: none;
}
.modal.user-form-modal #userPopup{
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 25px;
}
.btn-ceate-user{
  background-color: var(--red-bg-color);
}
.react-grid-HeaderCell{
  text-align: center;
}

.btn-acronym-save, .acronym-add-btn{
  min-width: 100px;
  background-color: var(--red-bg-color);
  border-color: var(--red-bg-color);
  border-style: solid;
  padding: 3px;
  color:white;
  cursor: pointer;
  border-radius: 0px !important;
}
.btn-acronym-cancel{
  border-color: var(--dark-grey-color);
  min-width: 100px;
  border-radius: 0px;
}
.btn-acronym-cancel:hover, .btn-acronym-save:hover{
  color: var(--font-black-color);
}
.chip-acronym .chip-colse{
  border: 1px solid;
    border-radius: 100%;
    height: 11px;
    width: 11px;
    display: inline-block;
    line-height: 0.6;
    position: relative;
    margin-left: 5px;
    font-size: 13px;
}
.rdg{
  min-height: 90vh;
  overflow: scroll;
}
.access-request-grid .rdg{
  min-height: 10vh;
  max-height: 200px;
  overflow-y: auto;
}
.rdg-cell{
  text-align: center;
}
.rdg-cell[aria-selected=true]{
  box-shadow: inset 0 0 0 2px var(--dark-grey-color) !important;
}
.rdg-checkbox-input:checked+.rdg-checkbox{
  background-color: var(--red-bg-color) !important;
}
.filter-cell{
  line-height: 35px;
  padding: 0;
}
.filter-inputclass-name{
  width: 100%;
  padding: 4px;
  font-size: 14px;
  max-height: 30px;
  line-height: 0;
}

.dropdown-user-profile.dropdown-menu{
  right: 0 !important;
  left: unset !important;
}
.user-avatar{
  margin-right: 4px;
}
.dropdown-item.active, .dropdown-item:active{
  background-color: var(--yellow-bg-color);
}
#userPopup .custom-checkbox .custom-control-input:checked~.custom-control-label:after, .acronyms-custom .custom-checkbox .custom-control-input:checked~.custom-control-label:after, .filter-list .custom-checkbox .custom-control-input:checked~.custom-control-label:after{
  content: "\1F5F8";
  color: white;
  top: 3px;
  padding-left: 2px;
  font-weight: bold;
  font-size: 12px;
  background-image: none !important;
}
#userPopup .custom-control-label, .acronyms-custom .custom-control-label{
  font-size: 0.875rem;
}
#userPopup .custom-control-label::before,#userPopup .custom-control-label::after, .acronyms-custom .custom-control-label::before, .acronyms-custom .custom-control-label::after, .filter-list .custom-control-label::before, .filter-list .custom-control-label::after{
  width: .8rem;
  height: .8rem;
  border-radius: 2px !important;
}
#userPopup .custom-control-input:checked~.custom-control-label::before, .acronyms-custom .custom-control-input:checked~.custom-control-label::before, .filter-list .custom-control-input:checked~.custom-control-label::before {
  border-color: var(--red-bg-color);
  background-color: var(--red-bg-color);
}
#userPopup .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before, .acronyms-custom .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgb(221 29 33 / 50%);
}
.filter-list .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after, .filter-list .custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before{
  border-color: var(--red-bg-color);
  background-color: var(--red-bg-color);
}
.filter-list .list-group.show .custom-control-label::before, .filter-list input.show+label::before{
  border-color: var(--red-bg-color) !important;
}
.filter-list div[aria-expanded="true"]{
  color: var(--red-bg-color);
}
.custom-switch .custom-control-input:checked~.custom-control-label::before{
  border-color: var(--main-bg-color);
  background-color: var(--main-bg-color);
}
.Toastify__progress-bar--success {
  background: var(--success-color) !important;
}

.checkbox-spinner{
  position: absolute;
  left: -4px;
  top: 3.2px;
  display: none;
}
.activate-loader{display: none;}
.activate-loader+.checkbox-spinner{
display: block;
}

input[type=checkbox]:checked, input[type=radio]:checked {
  accent-color: var(--red-bg-color);
}
.idle-timer-modal{
  position: absolute;
  top: 0;
  right: 0;
  z-index: -10;
  background-color: var(--white-bg-color);
}
.idle-timer-modal.show{
  z-index: 9999;
  display: block !important;
}
.idle-timer-modal-dialog{
  max-width: 300px;
}
.continue-session-btn{
  background-color: var(--red-bg-color);
  color: var(--main-layout-bg-color);
}

.react-daterange-picker{
  width: 100%;
}
.react-daterange-picker__calendar{
  z-index: 2 !important;
  box-shadow: 4px 4px 15px #ccc;
}
.react-calendar{
  font-family: "Futura", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
}
.react-calendar__tile--hasActive, .react-calendar__tile--now{
  background: var(--yellow-lite-color) !important;
}
.react-calendar__tile--active{
  background: var(--main-bg-color) !important;
}
.react-daterange-picker__wrapper{
  border: 2px solid var(--dark-grey-color) !important;
}
.react-daterange-picker__inputGroup__input::selection{
  background: var(--light-grey-color);
}
.react-daterange-picker__inputGroup__input{
  outline-color: var(--dark-grey-color);
}
.react-daterange-picker__wrapper svg{
  stroke-width: 1.6px !important;
  width: 15px;
}
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after{
  background-image: url(assets/indeterminate.svg) !important;
}
.rdg-cell:last-child .rdg-header-sort-cell{
  top: 0px !important;
  pointer-events: none;
}
span.rdg-header-sort-cell {
  top: -36px;
  position: relative;
  right: 2px;
}
.rdg-cell .rdg-header-sort-cell svg{
  visibility: hidden;
}
.rdg-cell[aria-sort="descending"] .rdg-header-sort-cell svg{
  visibility: visible;
}
.rdg-cell[aria-sort="ascending"] .rdg-header-sort-cell svg{
  transform: rotate(-180deg);
  visibility: visible;
}
.rdg-cell.filter-on:not(:last-child) span.rdg-header-sort-cell{
  top: -70px !important;
  display: none;
}
.rdg-cell[aria-sort="descending"]::after{
  content: '\25bc';
  right: 10px;
}
.rdg-cell[aria-sort="ascending"]::after{
  content: '\25b2';
  right: 10px;
}

/* ----Bow tie ---- */
.bowtie-wrapper {
  background-color: var(--white-bg-color);
}

.bowtiebtn {
  background-color: var(--yellow-bg-color);
  display: inline-block;
  width: 25px;
  height: 25px;
  text-align: center;
  border: none;
}

.bowtiebtn.download {
  background-image: url('./assets/download.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.bowtiebtn.edit {
  background-image: url('./assets/edit.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.bowtiebtn.closebtn span {
  color: var(--white-bg-color) !important;
  top: 0px;
  left: -1px;
  position: relative;
  border: 1px solid;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  display: block;
  line-height: 10px;
}

.bowtie-container {
  font-size: 0.75rem;
}

.bowtie-container .col-sm-2,
.bowtie-container .col-sm-3 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bowtie-container .list-group-item {
  padding: .5rem !important;
  word-break: break-word;
}

.bowtie-container .bowtie-column-header {
  text-align: center;
  font-weight: bold;
}

.bowtie-column-sub-header {
  font-size: .7rem;
}

.bowtie-row-container {
  height: 580px;
  max-height: 600px;
  max-width: 1440px;
}

.ellipse-shape {
  border-radius: 50%;
  min-width: 98%;
  min-height: 200px;
  max-height: 25%;
  background-color: #fbce07;
}

.consequence {
  background-color: #fbce07;
}

.cause {
  background-color: #bed50f;
}
.consolidated-summary .ellipse-shape {
  background-color: #ffc600;
}

.consolidated-summary .consequence {
  background-color: #c9ec9a;
}

.consolidated-summary .cause {
  background-color: #81ccd9;
}
.bowtie-cause-content .trash,
.bowtie-consequence-content .trash,
.bowtie-preventive-actions-content .trash,
.bowtie-recovery-actions-content .trash,
.bowtie-cause-content.active.onpdfdownload .trash,
.bowtie-consequence-content.active.onpdfdownload .trash,
.bowtie-preventive-actions-content.active.onpdfdownload .trash,
.bowtie-recovery-actions-content.active.onpdfdownload .trash {
  width: 15px;
  height: 15px;
  border: none;
  background-color: transparent;
  outline: none;
  display: none;
}
.bowtie-cause-content.active .trash, .bowtie-consequence-content.active .trash, .bowtie-preventive-actions-content.active .trash, .bowtie-recovery-actions-content.active .trash{
  display: block;
}
.bowtie-container .trash:hover{
  font-weight: bolder;
  opacity: 0.5;
  color: var(--red-bg-color);
}
.bowtie-preventive-actions-content {
  border-color: #bed50f !important;
}

.bowtie-recovery-actions-content {
  border-color: #fbce07 !important;
}

.bowtie-cause-content .list-group-item,
.bowtie-preventive-actions-content .list-group-item,
.bowtie-recovery-actions-content .list-group-item,
.bowtie-consequence-content .list-group-item {
  background-color: transparent !important;
}

.bowtie-event-content {
  padding: 10px 15px 10px 15px;
  text-align: center;
}

.preventive-actions {
  background-image: url(/src/assets/l_bow.png);
  background-position: 85% 50%;
  background-repeat: no-repeat;
}

.recovery-actions {
  background-image: url(/src/assets/r_bow.png);
  background-position: 30% 50%;
  background-repeat: no-repeat;
}

.consolidated-summary .recovery-actions {
  background-image: url(/src/assets/rc_bow.png);
  background-position: 30% 50%;
  background-repeat: no-repeat;

}

.consolidated-summary .preventive-actions {
  background-image: url(/src/assets/lc_bow.png);
  background-position: 85% 50%;
  background-repeat: no-repeat;

}
.bowtiepopup.modal.show {
  display: block;
}

.bowtie-cause-content,
.bowtie-preventive-actions-content,
.bowtie-recovery-actions-content,
.bowtie-consequence-content {
  overflow-y: auto;
  height: 540px;
  max-height: 540px;
  min-height: calc(100% - 30px);
}

@media (min-width: 1280px) {
  .bowtiepopup.modal .modal-dialog {
    max-width: 1200px;
  }

  .preventive-actions {
    background-image: url(/src/assets/l_bow.png);
    background-position: 89% 50%;
  }

  .recovery-actions {
    background-image: url(/src/assets/r_bow.png);
    background-position: 23% 50%;
  }

  .consolidated-summary .recovery-actions {
    background-image: url(/src/assets/rc_bow.png);
    background-position: 30% 50%;
    background-repeat: no-repeat;
  
  }
  
  .consolidated-summary .preventive-actions {
    background-image: url(/src/assets/lc_bow.png);
    background-position: 85% 50%;
    background-repeat: no-repeat;
  
  }
}

@media (min-width: 1440px) {
  .bowtiepopup.modal .modal-dialog {
    max-width: 1350px;
  }

  .preventive-actions {
    background-image: url(/src/assets/l_bow.png);
    background-position: 88% 50%;
  }

  .recovery-actions {
    background-image: url(/src/assets/r_bow.png);
    background-position: 25% 50%;
  }

  .consolidated-summary .recovery-actions {
    background-image: url(/src/assets/rc_bow.png);
    background-position: 30% 50%;
    background-repeat: no-repeat;
  
  }
  
  .consolidated-summary .preventive-actions {
    background-image: url(/src/assets/lc_bow.png);
    background-position: 85% 50%;
    background-repeat: no-repeat;
  
  }
}

#cost .clickactive .bar, #schedule .clickactive .bar, #probability .clickactive .bar, #mocBigRule.clickactive .bar,
.costquality.clickactive .bar, .COQquality.clickactive .bar, .processsafety.clickactive .bar, .rootcause.clickactive .bar,
#moccost .clickactive .bar{
  opacity: 0.1;
}
#cost .clickactive .bar.active, #schedule .clickactive .bar.active, #probability .clickactive .bar.active, #mocBigRule.clickactive g.active .bar,
.costquality.clickactive .bar.active, .COQquality.clickactive .bar.active, .processsafety.clickactive g.active .bar, .rootcause.clickactive g.active .bar,
#moccost .clickactive .bar.active{
  opacity: 1;
}
.bowtie-event-content::-webkit-scrollbar-track{
  background: #f1f1f180;
}
.thematic-bowtie-btn{
  background-color: var(--red-bg-color);
  color: var(--white-bg-color);
  border: none;
}
.thematic-bowtie-btn:hover{
  color: var(--white-bg-color);
}
.badge-acronym{
  background-color: var(--dark-grey-color);
  font-weight: normal !important;
  margin-right: 2px;
  border-radius: 2px !important;
  height: 15.5px;
}
.badge.badge-acronym.case-sensitive::after, .project-acronym-case.case-sensitive::after{
  display: inline-block;
  width: 10px;
  content: "\2713";
  margin-right: 2px;
  margin-left: -1px;
  border: 1px solid var(--dark-grey-color);
  height: 10px;
  font-size: .5rem;
  position: relative;
  top: -1.2px;
  color: var(--white-txt-color);
  background-color: var(--dark-grey-color);
  font-weight: bold;
}
span.grid-projectname, span.grid-acronym{
line-height: 1;
}
.project-popup{
  display: none;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
}
.project-popup.active{
  display: block;
}
.project-popup .project-popup-inner{
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
}
.project-popup-modal{
  background-color: var(--white-bg-color);
}
.close-project-popup{
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: .7;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.btn-acronym-save span{
  position: relative;
  top: 4px;
  display: none !important;
}
.btn-acronym-save.loading{
  padding: 1px !important;
  pointer-events: none !important;
}
.btn-acronym-save.loading span{
  display: inline-block !important;
}
/* -----eMoc---------- */
.emoc-results .emoc-item{
  background-color: var(--light-grey-color);
}
.emoc-results .emoc-item .emoc-item-intro{
  cursor: pointer;
}
.emoc-results .emoc-item .emoc-item-intro .emoc-titles{
  max-width: 650px;
}
.emoc-results .emoc-item .emoc-item-intro .main-title{
  font-family: "Futura", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.emoc-results .emoc-item .emoc-item-intro .sub-title{
  font-family: "FuturaLight", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  width: 650px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.emoc-results .emoc-item .emoc-description{
  font-family: "FuturaLight", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
}
.pie-dropdown{
  position: relative;
}
.emoc-sidebar{
  display: none;
}
.report-download-wrapper .form-check-label{
  margin-top: 4.5px;
}
.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered>li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}
.btn-white {
  background-color: var(--white-bg-color);
  font-size: 10px;
}
.btn-red {
  background-color: var(--red-bg-color);
  color: var(--white-bg-color);
  font-size: 10px;
}
.btn-red:hover{
  color: var(--white-bg-color);
}
.generate-report-btn{
  min-width: 120px;
}
.report-download-modal.modal.show{
  display: block;
}
.report-save-btn .Toastify__spinner{
  display: none;
}
.report-save-btn.active .Toastify__spinner{
  display: inline-block;
}
.report-save-btn.disabled{
  pointer-events: none !important;
}
/* change it later */
.emoc-sidebar .list-group-item.disabled{
  pointer-events: all !important;
  opacity: 1 !important;
  color: inherit;
}
.emoc-sidebar .filter-list.disabled .list-group-item{
  pointer-events: none !important;
}

.report-download-data-title::first-letter {
  text-transform: capitalize;
}
.report-download-data-title.rddt-eMoC::first-letter{
text-transform: none;
}
.accordion .btn.focus, .btn:focus {
  box-shadow: 0 0 0 0.2rem transparent !important;
}
.accordion button.btn-accordion::after{
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 1.7rem;
}
.accordion button.btn-accordion.collapsed::after {
  content: '\025BE';
}
.accordion button.btn-accordion:not(.collapsed)::after {
  content: '\025B4';
}
.recent-search-lists .recent{
  max-width: 450px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.autocomplete-recent-search{
  position: absolute;
  width: 100%;
  text-align: left;
  cursor: default;
  user-select: none;
  z-index: 900;
  display: none;
  background-color: var(--white-bg-color);
  max-height: 400px;
  overflow-y: auto;
  box-shadow: rgb(32 33 36 / 28%) 0px 1px 6px 0px;
}

input.search-input-field:focus ~ div.autocomplete-recent-search{
  border-color: red;
  display: block !important;
}

.autocomplete-recent-search .list-group .list-group-item svg {
  fill: var(--dark-grey-color);
}
.autocomplete-recent-search .list-group .list-group-item:hover, .autocomplete-recent-search .list-group .list-group-item.active{
  background-color: var(--light-grey-color);
  color: var(--font-black-color);
}
#mocPie.clickactive .slice.active, #MRF.clickactive .slice.active{
  stroke-width: 10;
  stroke-opacity: .3;
  stroke: #c22d31
}
.piesvg .slice{
  stroke-width: 0.2;
  stroke-opacity: 1;
  stroke: var(--font-black-color);
}
.top-report-option .form-check .form-check-label {
  position: relative;
  top: 4px
}

@media screen and (max-width:1440px) {
  .emoc-results .emoc-item .emoc-item-intro .emoc-titles,
  .emoc-results .emoc-item .emoc-item-intro .sub-title {
    max-width: 550px;
  }
}

@media screen and (max-width:1390px) {
  .emoc-results .emoc-item .emoc-item-intro .emoc-titles,
  .emoc-results .emoc-item .emoc-item-intro .sub-title {
    max-width: 450px;
  }
}

@media screen and (max-width:1200px) {
  .emoc-results .emoc-item .emoc-item-intro .emoc-titles,
  .emoc-results .emoc-item .emoc-item-intro .sub-title {
    max-width: 310px;
  }
}

.info-icon.info-white {
  text-align: center;
  width: 25px;
  height: 25px;
  border: 3px solid #fff;
  border-radius: 100%;
  font-weight: bolder;
  font-family: 'FuturaBold', Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  display: block;
}
.success-white-tickmark{
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: var(--success-color);
  color: var(--white-bg-color);
  line-height: 1.6;
  margin: 0px auto !important;
  top: 7px;
}
.request-access-accordion.accordion>.card{
  border-radius: 0px !important;
}
.request-access-accordion.accordion>.card>.card-header {
  margin-bottom: 0px;
  border: 1px solid rgba(0,0,0,.125);
  background-color: var(--white-bg-color);
  border-radius: 0px !important;
}
.request-access-accordion.accordion>.card>.card-header .ra-header-btn{
  color: var(--font-black-color)
}
.existing-report-delete-btn.active{
  display: none;
}
.existing-report-spinner{
  width: 16px !important;
  height: 16px !important;
  display: none;
}
.existing-report-delete-btn.active+.existing-report-spinner{
  display: block;
}
svg g{
  font-family: 'Futura', Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
}
.advanced-search-wrapper .risk-dropdown{
  display: none;
}
.advanced-search-wrapper.risk_advanced .risk-dropdown{
  display: block;
}
.advanced-search-wrapper .emoc-dropdown{
  display: none;
}
.advanced-search-wrapper.emoc_advanced .emoc-dropdown{
  display: block;
}
.advanced-search-wrapper .quality-dropdown{
  display: none;
}
.advanced-search-wrapper.quality_advanced .quality-dropdown{
  display: block;
}
.no-data-msg-placeholder{
  display: none;
}
.no-data-msg-placeholder.active{
  display: block;
}
.visit-count-cards .card-header:first-child{
  border-radius: 0;
}
.visit-count-cards .card-header{
  min-height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white-bg-color);
  color: var(--red-bg-color);
  cursor: pointer;
  transition: all 0.7s ease 0s;
}
.visit-count-cards .card-header:active, .visit-count-cards .card-header.active{
  background-color: var(--light-grey-color);
  color: var(--red-bg-color);
  cursor: auto;
  border-bottom-color: var(--main-bg-color);
  border-bottom-width: 3px;
}

.visit-count-cards .card-header h6{
  margin: 0;
}
fieldset.userview-fieldset, fieldset.filter-main, fieldset.custom-date-filter{
  border: 1px solid var(--dark-grey-color) !important;
  padding: 5px;
  min-height: 70px;
}
fieldset.userview-fieldset legend, fieldset.filter-main legend, fieldset.custom-date-filter legend{
  font-size: .85rem !important;
  width: auto !important;
  margin-bottom: 0px;
}
fieldset.userview-fieldset{
  min-height: 50px !important;
}
fieldset.custom-date-filter .react-daterange-picker__wrapper{
  border: none !important;
}
.popupmostnotrelevant-modal.show{
  display: block !important;
}
.bowtie-cause-content.onpdfdownload,
.bowtie-preventive-actions-content.onpdfdownload,
.bowtie-recovery-actions-content.onpdfdownload,
.bowtie-consequence-content.onpdfdownload,
.bowtie-row-container.onpdfdownload,
.bowtie-event-content.onpdfdownload,
.trash.onpdfdownlaod {
  height: auto !important;
  max-height: none !important;
}
.menu-btns{
  visibility: visible;
}
.menu-btns.onpdfdownload{
  visibility: hidden;
}
#bowtiedownload::after{
display: none !important;
}
.download-report-grid .rdg{
  min-height: 177px;
  height: 177px;
}
#userinfomodal.show{
  display: block;
}
.email-popover-link{
  color: var(--main-old-bg-color);
}
.email-popover-link:hover{
  color: var(--font-black-color);
}
.list-group-item.highlight{
  background-color: rgb(255 241 179) !important;
}
/* ==========Result card========== */
/* .search-item-inner{
  background-color: var(--light-grey-color);
} */
.search-item .result-content-item-intro .titles{
  cursor: pointer;
}
.search-item .result-content-item-intro .main-title{
  text-overflow: ellipsis;
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
}
.search-item .result-content-item-intro .sub-title{
  font-family: "FuturaLight", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-overflow: ellipsis;
  max-width: 450px;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width:1390px){
  .search-item .result-content-item-intro .main-title, .search-item .result-content-item-intro .sub-title{
    max-width: 450px;
  }
}
@media screen and (max-width:1200px){
  .search-item .result-content-item-intro .main-title, .search-item .result-content-item-intro .sub-title{
    max-width: 310px;
  }
}
@media screen and (max-width:1440px){
  .search-item .result-content-item-intro .main-title, .search-item .result-content-item-intro .sub-title{
    max-width: 550px;
  }
}
.search-item .report-controls label{
  font-size: 10px;
}
.search-item .report-controls .form-check-input{
  margin-top: .45rem;
}
.search-item .detail-content{
  font-family: "FuturaLight", Helvetica, Arial, Verdana, Tahoma, sans-serif;
  font-size: 1rem;
  background-color: var(--light-grey-color);
}
/* =======Result card end======== */
/* =============Quality data=========== */
.quality-sidebar{
  display: none;
}
.quality-filter-combobox .input-dl {
  border: 2px solid var(--dark-grey-color);
}
/* ======= */
.MuiAutocomplete-root{
  width: 100% !important;
}
.MuiAutocomplete-popper{
  width: 350px !important;
}
.MuiAutocomplete-popper li div{
  word-wrap: normal;
  font-size: 12px !important;
  font-family: "Futura", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
}
.MuiFormLabel-root{
  font-size: 12px !important;
  font-family: "Futura", Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
}
.PrivateNotchedOutline-root-1{
  border-radius: 0px !important;
  border: 2px solid var(--dark-grey-color) !important;
}
/* -------- for negative axis add a centerline at 0-------- */
svg .x.axis path, svg .y.axis path, .x.axis line, .y.axis line, .centerline {
  shape-rendering: crispEdges;
  fill: none;
  stroke: #000000 !important;
  stroke-width: 0.5px;
}

@media (min-width:1024px) and (max-width:1280px) {
  /* ============ Sidebar =========== */
  .sidebar-container span, .sidebar-container div{
    font-size: 0.75rem;
  }
  .sidebar{
    width: 220px !important;
  }
  .react-daterange-picker__inputGroup {
    min-width: calc((3px * 3) + 0.54em * 8 + 0.217em * 2) !important;
  }
  .filter-list .label{
    max-width: 120px;
  }
  .key-topics .key-btn{
    font-size: 0.75rem;
  }

  .makeStyles-formControl-1{
    max-width: 200px !important;
  }
}
.meta-info span{
  font-size: 10px;
  text-transform: capitalize;
}
#popupModalCustom.show{
  display: block;
}
#popupModalCustom{
  display: none;
}

.Mui-checked{
  color: var(--red-bg-color) !important;
}

.react-daterange-picker__clear-button{
  visibility: hidden;
}
#div_summary
{
  height: calc(100vh - 250px);
  overflow-y: auto;
  font-size: 15px;
  border-top: 1px solid #e1e1e1;
  padding-top: 10px;
}
#div_summary p
{
  margin-bottom: 0rem;
}
.summary-loader
{
  display: inline-block;
  margin-left: 10px;
}
.beta-tag
{
  font-size: 12px;
}
.summary-disclaimer
{
  margin-top: 10px;
  padding: 5px;
  color: #856404;
    background-color: #fff3cd;

}